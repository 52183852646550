
import { BaseAppConfig } from 'lib-customizations/types'

import { env, Env } from './env'

type AppConfigs = {
  default: BaseAppConfig
} & {
  [k in Env]: BaseAppConfig
}

const employeeConfigs: AppConfigs = {
  default: {
    sentry: {
      dsn: 'https://fe2f5fdd1ffe4aa39ff0054066e992fb@sentry.haltu.net/35',
      enabled: true,
    },
  },
  staging: {
    sentry: {
      dsn: 'https://fe2f5fdd1ffe4aa39ff0054066e992fb@sentry.haltu.net/35',
      enabled: true,
    },
  },
  prod: {
    sentry: {
      dsn: 'https://fe2f5fdd1ffe4aa39ff0054066e992fb@sentry.haltu.net/35',
      enabled: true,
    },
  },
}

const employeeMobileConfigs: AppConfigs = {
  default: {
    sentry: {
      dsn: 'https://fe2f5fdd1ffe4aa39ff0054066e992fb@sentry.haltu.net/35',
      enabled: true,
    },
  },
  staging: {
    sentry: {
      dsn: 'https://fe2f5fdd1ffe4aa39ff0054066e992fb@sentry.haltu.net/35',
      enabled: true,
    },
  },
  prod: {
    sentry: {
      dsn: 'https://fe2f5fdd1ffe4aa39ff0054066e992fb@sentry.haltu.net/35',
      enabled: true,
    },
  },
}

const citizenConfigs: AppConfigs = {
  default: {
    sentry: {
      dsn: 'https://fe2f5fdd1ffe4aa39ff0054066e992fb@sentry.haltu.net/35',
      enabled: true,
    },
  },
  staging: {
    sentry: {
      dsn: 'https://fe2f5fdd1ffe4aa39ff0054066e992fb@sentry.haltu.net/35',
      enabled: true,
    },
  },
  prod: {
    sentry: {
      dsn: 'https://fe2f5fdd1ffe4aa39ff0054066e992fb@sentry.haltu.net/35',
      enabled: true,
    },
  },
}

const employeeConfig = employeeConfigs[env()]
const employeeMobileConfig = employeeMobileConfigs[env()]
const citizenConfig = citizenConfigs[env()]

export { employeeConfig, employeeMobileConfig, citizenConfig }
